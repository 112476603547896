<template>
    <div>
        <div>
            <div v-show="showPlan"> 
            <van-nav-bar
            title="电费测算明细"
            left-arrow
            @click-left="onClickLeft"
        />
       <div class="basicDiv">
           
           <div class="linesDiv planText">
                <p>月均电费<span class="unit">(元)</span></p>
                <p>¥{{num_filter(data.oTotalCost)}}</p>
           </div>
           <div class="linesDiv planText">
                <p>月均度数<span class="unit">(度)</span></p>
                <p>{{num_filter(data.totalDegree)}}</p>
           </div>
           <div class="linesDiv planText">
                <p>原始均价<span class="unit">(元/度)</span></p>
                <p>¥{{num_filter(data.oAveragePrice)}}</p>
           </div>
                
           <div class="line_line"></div>
           <p class="titleText">度数明细</p>
           <!-- <p class="moneyText">1</p> -->
           <div class="linesDiv allText">
                <p>尖峰</p>
                <p>{{num_filter(data.tipPeakdegreeAverage)}}</p>
           </div>
           <div class="linesDiv allText">
                <p>高峰</p>
                <p>{{num_filter(data.highPeakdegreeAverage)}}</p>
           </div>
           <div class="linesDiv allText">
                <p>平谷</p>
                <p>{{num_filter(data.flatValley)}}</p>
           </div>
           <div class="linesDiv allText lastLine">
                <p>低谷</p>
                <p>{{num_filter(data.troughDegreeAverage)}}</p>
           </div>
           <div class="toMeasure" @click="toMeasure">申请优惠</div>
       </div> 
      
     
        <van-overlay :show="overlayPlanShow">
                <div class="wrapper" >
                   <div class="block_a" v-show="plan_a">
                                <p class="saveNum">¥ {{data.saveCostA ? (data.saveCostA.toFixed(0) * 1).toLocaleString():data.saveCostA}}</p> 
                                <p class="newMoney_a_1 ">¥ {{num_filter(data.discountDegree)}}</p>
                                <p class="newMoney_a_2 ">{{data.discount}}</p>
                                <p class="newMoney_a_3 ">¥ {{num_filter(data.afterDegrees)}}</p>
                                <img src="../../../../assets/image/powerMeasure/cancel.png" class="cancel"  @click="toHiddenApply"> 
                                <div class="applyDiv_b">
                                     <button class="toApplyMore" @click.stop  @click="toApplyBPlan">储能方案 ></button> 
                                </div>
                                  <button class="toApply" @click.stop @click="toApply()">正式申请</button> 
                    </div>
                    <div class="block_b" v-show="plan_b">
                                <div class="schemeExplain" @click.stop  @click="planBExplain">储能方案说明</div>
                                <img src="../../../../assets/image/powerMeasure/cancel.png" class="cancel"  @click="toHiddenApply"> 
                                <div class="applyDiv_b">
                                     <button class="toApply_b" @click.stop @click="toApplyBPlan">去测算</button>  
                                </div>
                    </div>
                </div>
            </van-overlay>
        </div>
        </div>
            <router-view></router-view>
    </div>
</template>
<script>
import {infoToEnclosure} from '../../../../network/addMes'
    export default{
        name:'PowerTest',
        data(){
            return{
                data:{},
                
                overlayPlanShow:false,
                assessId:'',
                customerNumber:'',
                plan_a:false,
                plan_b:false,
                showPlan:true
            }
        },
        props:{
           
        },
        methods:{
            num_filter(val){   
                if(val == null || val == undefined || val == 0){
                    return ;
                }
                val = val.toFixed(2);
                val = (val * 1).toLocaleString();
                return val;
            },

            onClickLeft(){
                this.$router.back();
            },
         
            toMeasure(){
                this.overlayPlanShow = true;
            },
            toHiddenApply(){
                this.overlayPlanShow = false;
            },
            toApply(){
                this.$router.push({
                     path: "basicMessage",
                     query:{
                        id:this.assessId
                     }
                });
            },
            planBExplain(){
                this.$router.push({
                     path: "powerTest/planExplain",
                     query:{
                        id:this.assessId,
                     }
                });
            },
            toApplyBPlan(){
                this.$router.push({
                    path: "powerTest/electricityBplan",
                    query:{
                        id:this.assessId,
                     }
                })
            }   
        },
        created() {
            let data = JSON.parse(localStorage.getItem('measure'));
            this.$toast.loading({
                        message: '努力加载中...',
                        forbidClick: false,
                        duration:0
                });
                infoToEnclosure(data).then((res)=>{
                this.$toast.clear();
                if(res.success){
                    
                    this.assessId = res.data.assessId
                    this.customerNumber = res.data.customerNumber
                    if(res.data.type == false){
                        this.data = res.data.discount_scheme
                        this.plan_b = true
                    }else{
                        this.data = res.data.discount_scheme
                        this.plan_a = true
                    }
                    
                }else{
                    this.plan_a = true
                    this.$toast('测算失败,请稍后重试')
                }
            })
        },
        watch: {
            $route: {
                handler: function (val) {
                    if (val.path.indexOf("planExplain") != -1 || val.path.indexOf("electricityBplan") != -1) {
                    this.showPlan = false;
                    } else {
                    this.showPlan = true;
                    }
                },
            },
        },
        mounted(){
            if (this.$route.path.indexOf("planExplain") != -1  || this.$route.path.indexOf("electricityBplan") != -1) {
                this.showPlan = false;
            }
        }
       
    }
</script>
<style scoped>
.basicDiv{
    width: 94%;
    margin: 30px auto;
    background-image: url('../../../../assets/image/powerMeasure/bg.png');
    background-size :100% 100%;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}

.titleText{
    margin-top: 49px;
    margin-left: 6%;
    margin-bottom: 20px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 42px;
}
.moneyText{
    font-size: 60px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-top: 29px;
}
.linesDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 86%;
    margin: 0 auto;
}
.planText{
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 90px;
}
.unit{
    font-size: 28px;
    padding-left: 10px;
}

.allText{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 55px;
}
.line_line{
    width: 88%;
    margin: 10px auto;
    border-bottom: 2px dashed #F6F7F9;
}
.lastLine{
    margin-bottom: 30px;
}
.toMeasure{
    margin: 100px auto;
  
    width: 616px;
line-height: 100px;
background: linear-gradient(0deg, #F4C830, #FFF49B);
box-shadow: 4px 7px 16px 2px rgba(1,87,105,0.7);
border-radius: 50px;
text-align: center;
font-size: 46px;
font-family: PingFang SC;
font-weight: 600;
color: #DB3D16;
text-shadow: 2px 4px 0px rgba(255,255,255,0.7);
}
.wrapper {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    height: 100%;
    flex-direction: column;
  }
 
.cancel{
    width: 50px;
    height: 50px;
    position: absolute;
    right: -50px;
    top: 25px;

  }
 

.saveNum{
  margin-top: 249px;
    font-size: 52px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 71px;
    color: #E21F00;
}
.newMoney_a_1{
    margin-top: 110px;
    font-size: 52px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 71px;
    color: #E21F00;
}
.newMoney_a_2{
    margin-top: 105px;
    font-size: 52px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 71px;
    color: #E21F00;
}
.newMoney_a_3{
    margin-top: 125px;
    font-size: 52px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #E21F00;
    line-height: 36px;
    
}


.block_a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 56%;
    height: 926px;
    background-image: url('../../../../assets/image/powerMeasure/result_a.png');
    background-size: cover;
    position: relative;
    margin-top: 10px;
    margin: 40px auto;
  }
.block_b{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 82%;
    height: 864px;
    background-image: url('../../../../assets/image/powerMeasure/result_b.png');
    background-size: cover;
    /* position: relative; */
    margin: 0 auto;
    margin-top: 190px;
}


.applyDiv_b{
    display: flex;
    justify-content: space-around;
    width: 70%;
    text-align: center;
    margin-top: 55px;
}

.toApply{
    margin-top: 40px;
    width: 520px;
    background: linear-gradient(180deg, #13D8D6, #0EA6A6, #139978);
    border-radius: 35px;
    font-size: 37px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 70px;
    text-shadow: 0px 4px 0px rgba(8,113,99,0.93);
}
.toApplyMore{
    width: 262px;
    background: linear-gradient(180deg, #FEF7C5, #FEDA4B, #FAA050);
    box-shadow: 0px 5px 15px 1px rgba(132,8,8,0.39);
    border-radius: 28px;
  
    font-size: 29px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #D72F22;
    line-height: 56px;
    text-shadow: 0px 3px 0px rgba(254,247,181,0.93);
}
.toApply_b{
    width: 96px;
    font-size: 33px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    width: 253px;
    text-align: center;
    line-height: 80px;
    background: linear-gradient(180deg, #D19E52 0%, #D19E52 100%);
    border-radius: 20px;
    margin-top: 850px;
}
.schemeExplain{
  position: absolute;
  right: 9%;
  top: 220px;
font-size: 32px;
font-family: PingFang SC;
font-weight: 500;
color: #FFFFFF;
width: 220px;
line-height: 60px;
text-align: center;
background: linear-gradient(180deg, #D19E52 6%, #D19E52 100%);
box-shadow: 1px 2px 0px 0px rgba(5,145,157,0.4);
border-radius: 30px 0px 0px 30px;
}
</style>
